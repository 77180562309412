import React from 'react'
import { motion } from "framer-motion"
import { useStateValue } from '../StateProvider';

function Dispo({id, imgSrc, nom, qualite}) {

  const [{basket}, dispatch] = useStateValue();

  const addToBasket = () => {
    //dispatch the item into the data layer
    dispatch({
      type: "ADD_TO_BASKET",
      item: {
        id: id,
        nom: nom,
        imgSrc: imgSrc,
        qualite: qualite,
     
      },
    });
  };


  return (
    <motion.div
    initial={{y:-100, opacity:0, }}
    transition={{duration:1.2}}
    whileInView={{opacity:1, y:0}}
    viewport={{once: true}}
     className='flex-col m-4 '>
        <div className= 'flex flex-col bg-slate-500/70 items-center justify-center self-center w-full rounded-md  shadow-lg hover:shadow-white transition-all hover:scale-105 duration-300 ease-in-out cursor-pointer'
    >
    <div className='flex flex-col items-center justify-between space-y-4 p-2 border border-white rounded-xl'>
   
          <div>
          <img src={imgSrc} alt={nom}  className='w-[320px] h-[300px]  p-2 z-[2] drop-shadow-lg rounded-[20px]'/>
          </div>
        <div>
            <h2 className='items-center justify-center text-xl text-white font-semibold uppercase '>
             {nom}
            </h2>
        </div>
        <div>
        <h2 className='items-center justify-center text-lg text-white font-semibold uppercase '>
             Type : {qualite}
        </h2>
        </div>
        <div>
            <button className='rounded-lg bg-white px-5 py-2 text-xl text-black hover:bg-slate-400 mb-6 font-extrabold'  onClick={addToBasket}>Commande</button>
        </div>
    </div>
        
    </div>
    </motion.div>
  )
}

export default Dispo