import React from 'react'
import { HiLocationMarker } from "react-icons/hi";
import { Link } from 'react-router-dom';
import Logo from '../img/logo3.png'

function Footer() {
  return (
    <div className='grid sm:grid-cols-3 gap-8 bg-[#2C3333] mt-2 px-3 pt-4 text-slate-300 items-center w-full'>
      
      <div className='flex items-center justify-center'>
     
      <Link to="/"><img src={Logo} alt="Logo de Ps-Group" className='max-h-[150px] animate-pulse cursor-pointer'/></Link>

      </div>

      <div className=''>
        <h1 className='font-extrabold text-lg'><b>Nos Contacts</b></h1>
        <ul className='flex gap-x-2 text-sm'>
          <li>Cel:</li>
          <li>(+225) 07 48 36 41 68</li>
        </ul>
        <ul className='flex gap-x-2 text-sm'>
          <li>Cel:</li>
          <li>(+225) 07 07 77 12 71</li>
        </ul>
        <ul className='flex gap-x-2 text-sm'>
          <li>Cel:</li>
          <li>(+44) 796 077 5370</li>
        </ul>
        <ul className='flex gap-x-2 text-sm'>
          <li>Email:</li>
          <li>contact@psgroupci.com</li>
        </ul>
        <ul className='flex gap-x-2 text-sm'>
          <li>Email::</li>
          <li>planetesolutiongroup@gmail.com</li>
        </ul>
        <ul className='flex gap-x-2 text-sm cursor-pointer'>
          <li>siteweb:</li>
          <li><Link to="/">www.psgroupci.com</Link></li>
        </ul>
      </div>

      <div className='space-y-2'>
      <h1 className='font-extrabold text-lg'><b>Localisation</b></h1>
        <ul className='flex gap-x-2 text-sm'>
          <li>Siège:</li>
          <li>Yopougon Attié près Lycée Technique</li>
        </ul>
        <ul className='flex gap-x-2 text-sm'>
          <li>Carrière de</li>
          <li className='font-bold'>PLANETE SOLUTION GROUP</li>
        </ul>
        <ul className='flex gap-x-2 text-sm'>
          
          <a href="https://maps.app.goo.gl/Rm5YjcCHW7CituEYA" target="_blank"
        rel="noreferrer" className=' flex gap-x-4 mt-2 text-xl font-bold' ><HiLocationMarker /> Trouvez-nous ici</a>
        </ul>
       
        
      </div>
    </div>
  )
}

export default Footer