import React from 'react'
import Top from './Top'
import { useStateValue } from "../StateProvider";
import Dv from '../img/dv.jpg'
import logo3 from '../img/logo3.png'
import DispoCheckout from './DispoCheckout'
import {useNavigate} from 'react-router-dom';

function Checkout() {

  const [{basket}] = useStateValue();

  const navigate = useNavigate();

  const command =()=>{

    navigate('/commande')
  }


  






  return (
    <div className='appBackground'>
        <Top  image={Dv}/>
        <div className='grid md:grid-cols-2 gap-4'>
            

            <div className='flex-1'>
            {basket?.length>0  ? (<>
                {basket && basket.map((item)=>(
                    <div
                    key={item.id}
                    className=""
                    >
                    <DispoCheckout  
                    imgSrc={item.imgSrc}
                    nom={item.nom}
                    qualite={item.qualite}
                    />

                    </div>
                ))}
                <div className='flex items-center justify-center mb-6'><button className='rounded-lg bg-white px-3 py-1 sm:px-5 sm:py-2 text-md sm:text-xl text-black hover:bg-slate-400 font-extrabold'  onClick={command}>Valider ma commande</button></div>
            </>):(<>
            {(navigate('/produits'))}
            </>)}

        
            </div>
            
            <div className='flex-1 justify-center items-center w-full '>
               
               <img src={logo3} alt="" className='w-[300px] mx-auto' />
               <p className='text-center text-xl font-bold'>Merci de nous faire confiance</p>
               
           </div>
        </div>
           
    </div>
  )
}

export default Checkout