import React from 'react'


function Top({image}) {
  return (
    <div className=''>
        <img src={image} alt="" className='w-full h-[100px]'/>
    </div>
  )
}

export default Top