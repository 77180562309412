import React from 'react'
import Footer from './Footer'
import Header from './Header'

function Layout(props) {

    const { children } = props

  return (
    <> 
      <Header />
        <main className='flex flex-col'>
            {children}
        </main>
      <Footer />
    </>
  )
}

export default Layout

