import { Navigate, Route, Routes } from 'react-router-dom';
import Actualite from './components/Actualite';
import Checkout from './components/Checkout';
import Commande from './components/Commande';
import Contact from './components/Contact';
import Home from './components/Home';
import Layout from "./components/Layout";
import Produits from './components/Produits';
import QuiSommes from './components/QuiSommes';


function App() {
  return (
    <div className='flex items-center justify-center flex-col'>
      <Layout>
        <Routes> 
          <Route path='/' element={ <Home />}/>
          <Route path='/quisommesnous' element={ <QuiSommes />}/>
          <Route path='/produits' element={ <Produits />}/>
          <Route path='/actualite' element={ <Actualite />}/>
          <Route path='/contact' element={ <Contact />}/>
          <Route path='*' element={ <Navigate to="/" />}/>
          <Route path='/checkout' element={ <Checkout />}/>
          <Route path='/commande' element={ <Commande />}/>
        </Routes>
      </Layout>
    </div>
  );
}

export default App;
