import React from 'react'
import { Helmet } from "react-helmet";
import { GranitesAll } from '../data/granitesDataAll'
import Dv from '../img/dv.jpg'
import Dispo from './Dispo'
import Top from './Top'


function Produits() {
  return (
    <div>

      <Helmet>
        
        <title>Nos Produits</title>
        <meta
            name="Produits"
            content="Bienvenue sur la page des produits de PS-Groupe. Nous avons actuellement dans nos stock les produits suivants..." 
        />
      </Helmet>

      <Top  image={Dv}/>

      <div className='relative flex flex-col mt-6 ProductBackground items-center'>
       <div className='absolute w-full h-full bg-black/40 ' />
        <div className='z-[2] flex flex-1 items-center justify-center text-2xl sm:text-4xl text-center text-white uppercase gap-x-3 my-6 font-bold'>
        <p className= 'flex flex-col bg-slate-500/70 items-center justify-center self-center w-full shadow-lg p-5 rounded-xl'>Actuellement disponible chez nous</p> 
        </div>
        <div  className=" grid md:grid-cols-2 lg:grid-cols-3 gap-6 z-[2]">
          
           {GranitesAll && GranitesAll.map((data)=>(
            <div
            key={data.id}
            className=""
            >
              <Dispo 
              imgSrc={data.imgSrc}
              nom={data.nom}
              qualite={data.qualite}
              />

            </div>
           ))}
         
        </div>
    </div>

      
    </div>
  )
}

export default Produits