import React from 'react'
import { Helmet } from "react-helmet";
import Accueil from './Accueil'
import Disponible from './Disponible'
import Hero from './Hero'
import TransitAcceuil from './TransitAcceuil'

function Home() {
  return (
    <div className='flex flex-col'>
       <Helmet>
  
        <title>Accueil</title>
        <meta
            name="Accueil"
            content="Bienvenue sur la page d'accueil de PS-Groupe. PLANETE SOLUTION GROUP envisage d’offrir une large gamme de concassés de granites sûrs, des infrastructures intelligentes qui amélioreront la vie des êtres vivants et qui permettront la réduction de la consommation en énergies en fournissant de l’énergie verte et d’autres types d’énergies non polluante." 
        />
      </Helmet>

        <Hero />
        <Accueil />
        <TransitAcceuil />
        <Disponible />
    </div>
  )
}

export default Home