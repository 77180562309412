import ContactContent from "./ContactContent"
import { Helmet } from "react-helmet";
import Top from './Top'
import Dv from '../img/dv.jpg'

function Contact() {
  return (

    <div>
       <Helmet>
  
        <title>Contact</title>
        <meta
            name="Contact"
            content="Contacter PS-Groupe au: Cel:(+225) 07 48 36 41 68 / (+225) 07 07 77 12 71 /(+44) 796 077 5370  Email: contact@psgroupci.com / 
            planetesolutiongroup@gmail.com
            siteweb: www.psgroupci.com" 
        />
      </Helmet>
       <Top  image={Dv}/>
      <ContactContent />
    </div>
    
  )
}

export default Contact