import img1 from '../img/granite/concasse0s31v5.jpg'
import img2 from '../img/granite/concasse0s40.jpeg'
import img3 from '../img/granite/concasse25s40.jpeg'
import img4 from '../img/granite/grainderiz4s6.jpeg'
import img5 from '../img/granite/gravillion4s10.jpg'
import img6 from '../img/granite/gravillon5s15.jpeg'
import img7 from '../img/granite/gravillon5s25.jpg'
import img8 from '../img/granite/gravillon10s14.jpg'
import img9 from '../img/granite/gravillon14s20.jpg'




const Granites = [

  {
    id: 1,
    imgSrc: img6,
    nom: "GRAVILLON",
    qualite:'5/15',
  },
  {
    id: 2,
    imgSrc: img7,
    nom: "GRAVILLON",
    qualite:'5/25',
  },
    {
      id: 3,
      imgSrc: img1,
      nom: "CONCASSE",
      qualite:"0/31.5",
    },
    {
      id: 4,
      imgSrc: img2,
      nom: "CONCASSE",
      qualite:"0/40",
    },
    {
      id: 5,
      imgSrc: img3,
      nom: "CONCASSE",
      qualite:'25/40',
    },
    {
      id: 6,
      imgSrc: img4,
      nom: "GRAIN DE RIZ",
      qualite:'4/6',
    },
    {
      id: 7,
      imgSrc: img5,
      nom: "GRAVILLON",
      qualite:'4/10',
    },
   
    {
      id: 8,
      imgSrc: img8,
      nom: "GRAVILLON",
      qualite:'10/14',
    },
    {
      id: 9,
      imgSrc: img9,
      nom: "GRAVILLON",
      qualite:'14/20',
    },
  
]

export {Granites}