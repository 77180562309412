import React from 'react'
import { Parallax, Background } from 'react-parallax';
import Granite from '../img/granite.jpg'

function TransitAcceuil() {
  return (
   
    <Parallax
        bgImage={Granite}
        renderLayer={percentage => (
            <div
                style={{
                    position: 'absolute',
                    background: `rgba(255, 125, 0, ${percentage * 0.7})`,
                    left:'50%',
                    top: '30%',
                    width: percentage * 500,
                    height: percentage * 500,
                }}
                
            >
              <p className='text-white text-xl sm:text-5xl p-2 sm:p-4 justify-center top-50 mt-2 sm:mt-20'>
              Le bonheur d'une planète saine
              </p>
            </div>
        )}
        className='w-[440px] h-[120px] sm:w-[1440px] sm:h-[450px] object-cover mt-6'
    >
        <p className='text-black  font-bold text-2xl  sm:text-5xl p-1 sm:p-4 justify-center top-5 sm:top-50 mt-2 sm:mt-20 '>
        PLANETE SOLUTION GROUP
        </p>
    </Parallax>
  )
}

export default TransitAcceuil