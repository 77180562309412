import React from 'react'
import Present from '../img/presentation.jpg'
import Perso from '../img/personel.jpg'
import { motion } from "framer-motion"

function Accueil() {

    const transition= {type: "spring", duration: 3}
  return (
    <div className='  justify-center items-center p-4'>
        
        
        <div class="grid md:grid-cols-2 gap-6 bg-white w-full mt-10 ">
            <div class="flex-1 flex-col gap-2">
              <div className=' flex flex-col justify-center items-center space-y-6 my-3 mx-4'>
                <motion.div 
                    initial={{opacity:0, x:100}}
                    whileInView={{opacity:1, x:0}}
                    transition={{...transition, duration:2}}
                    className='relative w-[300px] h-[156px] sm:w-[500px] sm:h-[260px] bg-orange-400 '
                >
                  <motion.img 
                    initial={{opacity:0, x:-100}}
                    whileInView={{opacity:1, x:0}}
                    transition={transition}
                    src={Present} 
                    alt="la carrière de PS-Group" 
                    className='shadow-md object-right w-[500px] h-[303px] sm:w-[500px] sm:h-[260px] -bottom-6 -right-6 absolute mb-2' 
                  />
              
              </motion.div>
                <div className=' flex flex-col justify-center items-center space-y-6 my-3 mx-4 '>
                  < motion.div 
                      initial={{opacity:0, x:100}}
                      whileInView={{opacity:1, x:0}}
                      transition={{...transition, duration:2}}
                      className='relative w-[300px] h-[156px] sm:w-[500px] sm:h-[260px] bg-orange-400 mt-2'
                  >
                    <motion.img 
                      initial={{opacity:0, x:-100}}
                      whileInView={{opacity:1, x:0}}
                      transition={transition}
                      src={Perso} 
                      alt="éuipe fondatrice de PS-Group" 
                      className='shadow-md object-right w-[500px] h-[303px] sm:w-[500px] sm:h-[260px] -bottom-6 -right-6 absolute' 
                    />
                
                  </motion.div>
                </div>
              </div>
               
            </div>

            <div class="flex flex-col sm:flex-1 mt-8 sm:mt-0">
              
                <div className='max-w-[400px] sm:max-w-[500px] mt-6 items-center'>
                  <p className='text-justify'>
                    Les questions environnementales et sociales sont devenues tellement cruciales que le non-respect des mesures de sauvegardes peut avoir des conséquences fâcheuses sur les projets. Pour éviter, minimiser, atténuer et/ou compenser les impacts environnementaux et sociaux négatifs des projets, une équipe de promoteurs, de professionnels sérieux et engagé, ont décidé́ de créer un groupe dénommé <b>PLANETE SOLUTION GROUP</b> en abrégé <b>PS-GROUP</b>  <br />
                    <b>PLANETE SOLUTION GROUP </b>est une plateforme s’inscrivant dans le développement durable qui couvre plusieurs domaines d’activités sectoriels (DAS) essentiellement : l’industrie extractive (carrière de granite), le BTP et  les énergies vertes. <br />
                    Le développement durable est « un développement qui répond aux besoins des générations du présent sans compromettre la capacité des générations futures à répondre aux leurs. Deux concepts sont inhérents à cette notion : le concept de « besoins », et plus particulièrement des besoins essentiels des plus démunis à qui il convient d’accorder la plus grande priorité́. L’idée des limitations que l’état de nos techniques et de notre organisation sociale impose sur la capacité de l’environnement à répondre aux besoins actuels et à venir. » 
                      <br />   
                  </p>
                  
                  <p className='mt-2 text-justify'>
                    Nos principales activités sont l’industrie extractive ( exploitation industrielle de granite concassé ) et le BTP ( la construction de bâtiments et les travaux publics ). 
                  </p>
                </div>
            </div>
            
        </div>



        
        
    </div>
  )
}

export default Accueil