import React from 'react'
import Top from './Top'
import Dv from '../img/dv.jpg'
import AcutualiteContent from './AcutualiteContent'


function Actualite() {
  return (
    <div>
    <Top  image={Dv}/>
    <AcutualiteContent />
    </div>
  )
}

export default Actualite