import React, { useState, useEffect } from 'react';
import { useStateValue } from "../StateProvider";
import Logo from '../img/logo3.png'
import { AiOutlineMenu, AiOutlineClose } from 'react-icons/ai';
import { HiShoppingCart } from "react-icons/hi";
import { Link } from 'react-router-dom';

function Header() {

  const [{ basket }] = useStateValue();

  const [nav, setNav] = useState(false);
  const [color, setColor] = useState('transparent');
  const [textColor, setTextColor] = useState('white');
  const [borderLine, setBorderLine] = useState(false)

  const handleNav = () => {
    setNav(!nav);
  };

  useEffect(() => {
    const changeColor = () => {
      if (window.scrollY >= 90) {
        setColor('#DDDDDD');
        setTextColor('#000000');
        setBorderLine(true)
      } else {
        setColor('transparent');
        setTextColor('#ffffff');
        setBorderLine(false)
      }
    };
    window.addEventListener('scroll', changeColor);
  }, []);

  return (
    <div 
     style={{ backgroundColor: `${color}` }}className='fixed left-0 top-0 w-full z-[10] ease-in duration-300  text-white font-thin  '>
        <div style={{backgroundColor: `${color}` }}  className={
          'flex justify-between items-center w-full px-4 py-2 ease-in duration-300 '
        }>
                
           <img src={Logo} alt="" className='h-[60px]'/>
    
       
        <ul style={{ color: `${textColor}` }}   className='hidden sm:flex space-x-10'>

                <li className=' cursor-pointer py-1 duration-300 relative font-bold text-xl after:absolute after:bottom-0 after:right-full after:bg-white after:z-10 after:w-full after:h-[2px] overflow-hidden hover:after:translate-x-full after:duration-300 hover:text-white'>
                  <Link to='/'>
                    Accueil
                  </Link>
                </li>
        
                <li className='cursor-pointer py-1 duration-300 relative font-bold text-xl after:absolute after:bottom-0 after:right-full after:bg-white after:z-10 after:w-full after:h-[2px] overflow-hidden hover:after:translate-x-full after:duration-300 hover:text-white'>
                  <Link to='/quisommesnous'>
                    Qui sommes-nous?
                  </Link>
                </li>

                <li className='cursor-pointer py-1 duration-300 relative font-bold text-xl after:absolute after:bottom-0 after:right-full after:bg-white after:z-10 after:w-full after:h-[2px] overflow-hidden hover:after:translate-x-full after:duration-300 hover:text-white'>
                  <Link to='/produits'>
                    Nos produits
                  </Link>
                </li>

                <li className='cursor-pointer py-1 duration-300 relative font-bold text-xl after:absolute after:bottom-0 after:right-full after:bg-white after:z-10 after:w-full after:h-[2px] overflow-hidden hover:after:translate-x-full after:duration-300 hover:text-white'>
                  <Link to='/actualite'>
                  Actualité
                  </Link>
                </li>

                <li className='cursor-pointer py-1 duration-300 relative font-bold text-xl after:absolute after:bottom-0 after:right-full after:bg-white after:z-10 after:w-full after:h-[2px] overflow-hidden hover:after:translate-x-full after:duration-300 hover:text-white'>
                  <Link to='/contact'>
                    Contact
                  </Link> 
                </li>
                <li >
                {basket?.length>0 && <Link to="/checkout" >
                <div className='link relative flex items-center justify-center cursor-pointer'>
                    <span className='absolute top-0 right-0 md:right-10 h-4 w-4 bg-orange-400 text-center text-white font-bold rounded-full items-center justify-center text-xs'>{basket?.length}</span>
                  <HiShoppingCart className='h-10 w-8 '/>
                  <p className='hidden md:inline mt-2 font-bold md:text-sm'>basket</p>
                  </div>
                </Link> }
                </li>
            
        </ul>

         {/*Mobile button*/}
         <div className='blck sm:hidden z-10'>
         {basket?.length>0 && <Link to="/checkout" >
                <div className='link relative flex items-center justify-center cursor-pointer'>
                    <span className='absolute top-0 right-0 md:right-10 h-4 w-4 bg-orange-400 text-center text-white font-bold rounded-full items-center justify-center text-xs'>{basket?.length}</span>
                  <HiShoppingCart className='h-10 w-8 '/>
                  <p className='hidden md:inline mt-2 font-bold md:text-sm'>basket</p>
                  </div>
            </Link> }
         </div>
         
         <div onClick={handleNav} className='block sm:hidden z-10 '>
         
                {nav ? <AiOutlineClose size={20} style={{ color: `${textColor}` }} /> : <AiOutlineMenu size={20} style={{ color: `${textColor}` }} />}
            </div>
            {/*Mobile menu*/}
        <div
          className={
            nav
              ? 'sm:hidden absolute top-0 left-0 right-0 bottom-0 flex justify-center items-center w-full h-screen bg-black text-center ease-in duration-300'
              : 'sm:hidden absolute top-0 left-[-100%] right-0 bottom-0 flex justify-center items-center w-full h-screen bg-black text-center ease-in duration-300'
          }
        >
          <ul className='flex flex-col items-center justify-center space-y-2'>

          <li className=' py-1 duration-300 relative font-bold text-xl after:absolute after:bottom-0 after:right-full after:bg-white after:z-10 after:w-full after:h-[2px] overflow-hidden hover:after:translate-x-full after:duration-300 hover:text-white'>
            <Link to='/' onClick={handleNav} >
              Accueil
            </Link>
          </li>
          <li className=' py-1 duration-300 relative font-bold text-xl after:absolute after:bottom-0 after:right-full after:bg-white after:z-10 after:w-full after:h-[2px] overflow-hidden hover:after:translate-x-full after:duration-300 hover:text-white'>
            <Link to='/quisommesnous' onClick={handleNav} >
              Qui sommes-nous?
            </Link>
          </li>

          <li className=' py-1 duration-300 relative font-bold text-xl after:absolute after:bottom-0 after:right-full after:bg-white after:z-10 after:w-full after:h-[2px] overflow-hidden hover:after:translate-x-full after:duration-300 hover:text-white'>
            <Link to='/produits' onClick={handleNav}>
              Nos produits
            </Link>
          </li>
          
          <li className=' py-1 duration-300 relative font-bold text-xl after:absolute after:bottom-0 after:right-full after:bg-white after:z-10 after:w-full after:h-[2px] overflow-hidden hover:after:translate-x-full after:duration-300 hover:text-white'>
            <Link to='/actualite' onClick={handleNav}>
             Actualité
            </Link>
          </li>

          <li className=' py-1 duration-300 relative font-bold text-xl after:absolute after:bottom-0 after:right-full after:bg-white after:z-10 after:w-full after:h-[2px] overflow-hidden hover:after:translate-x-full after:duration-300 hover:text-white'>
            <Link to='/contact' onClick={handleNav} >
              Contact
            </Link> 
          </li>
            
          </ul>
        </div>
        
      </div>
        {borderLine && <div className='flex h-2 bg-orange-300'/>}
          
        
    </div>
  );
}

export default Header