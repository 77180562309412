import React from 'react'
import logo3 from '../img/logo3.png'
import { motion } from 'framer-motion'

function ContactContent() {
  return (
    <motion.div
    initial={{y:-100, opacity:0, }}
    transition={{duration:1.2}}
    whileInView={{opacity:1, y:0}}
    viewport={{once: true}}

    className='grid md:grid-cols-2 gap-4'
    >

       <div><div className='tracking-[10px] text-center font-extrabold  text-xl  sm:mt-10 text-black px-40 mx-auto'> 
          Contacter nous 
        </div>

        <div className='flex-1 items-center'>
            <form className=''>

              <div className='flex space-x-2'>
                <p className='flex-1'>
                  <input 
                  type="text" 
                  placeholder="Votre nom"
                  className="w-full m-2  border-b-slate-800 border-2 no-select p-2 outline-none rounded-md"
                  />
                </p>

                <p className='flex-1'>
                  <input 
                  type="text" 
                  placeholder="Votre Prénoms"
                  className="w-full m-2  border-b-slate-800 border-2 no-select p-2 outline-none rounded-md"
                  />
                </p>

              </div>

              <div className='flex space-x-2'>
                <p className='flex-1'>
                  <input 
                  type="email" 
                  placeholder="Votre email"
                  className="w-full m-2  border-b-slate-800 border-2 no-select p-2 outline-none rounded-md"
                  />
                </p>

                <p className='flex-1'>
                  <input 
                  type="text" 
                  placeholder="Votre Numéro cell.."
                  className="w-full m-2  border-b-slate-800 border-2 no-select p-2 outline-none rounded-md"
                  />
                </p>
                
              </div>
              <textarea name="" id="" cols="30" rows="10"  className="w-full m-2  border-b-slate-800 border-2 no-select p-2 outline-none rounded-md" placeholder='Votre message'>

              </textarea>
              <button className="w-full m-2 p-2 rounded-md bg-cyan-500 hover:bg-cyan-600 text-2xl text-white" >
                Valider ma demande
              </button>
            </form>

          </div></div>
          <div className='flex-1 justify-center items-center w-full '>
               
               <img src={logo3} alt="Logo de PS-Group" className='w-[300px] mx-auto' />
               <p className='text-center text-xl font-bold'>Merci de nous faire confiance</p>
               
           </div>


    </motion.div>
  )
}

export default ContactContent