import React from 'react'
import Indus from '../img/indus.jpg'

function AcutualiteContent() {
  return (
    <div className='flex flex-col space-y-4 items-center justify-center'>
        <p className='text-5xl text-center mt-4'>Nous somme en construction</p>
        <img src={Indus} alt="en cours de construction" className='h-[300px] w-[200px]' />
    </div>
  )
}

export default AcutualiteContent