import React from 'react'
import { useStateValue } from '../StateProvider';

function DispoCheckout({id, imgSrc, nom, qualite}) {

  const [{basket}, dispatch] = useStateValue();

  const removeFromBasket = () => {
    dispatch({
      type: "REMOVE_FROM_BASKET",
      id: id,
    });
  };


  return (
    <div className= 'flex  bg-slate-500/40 items-center w-full  shadow-lg hover:shadow-white transition-all hover:scale-105 duration-300 ease-in-out cursor-pointer sm:m-6 my-4  sm:max-w-2xl justify-between p-2 sm:p-4 rounded-2xl'>
      <div>
        <img src={imgSrc} alt={`${nom} de ps-group`} className='rounded-full drop-shadow-lg w-20 h-20 sm:w-40 sm:h-40'/>
      </div>
      <div className='text-md sm:text-xl font-bold text-white'>
        <p>{nom}</p>
      </div>
      <div className='text-md sm:text-xl font-bold text-white'>
        <p>{qualite}</p>
      </div>
      <div>
        <button className='rounded-lg bg-white px-3 py-1 sm:px-5 sm:py-2 text-md sm:text-xl text-black hover:bg-slate-400 font-extrabold' onClick={removeFromBasket}>Retirer</button>
       </div>
        
    </div>
  )
}

export default DispoCheckout