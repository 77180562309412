import React from 'react'
import { Helmet } from "react-helmet";
import Top from './Top'
import Dv from '../img/dv.jpg'
import Presentation from './Presentation'


function QuiSommes() {
  return (
    
    <div className='flex flex-col'>
      <Helmet>
  
        <title>Qui sommes-nous?</title>
        <meta
            name="Pésentation"
            content="PLANETE SOLUTION GROUP envisage d’offrir une large gamme de concassés de granites sûrs, des infrastructures intelligentes qui amélioreront la vie des êtres vivants et qui permettront la réduction de la consommation en énergies en fournissant de l’énergie verte et d’autres types d’énergies non polluante."
        />
      </Helmet>
      
        <Top  image={Dv}/>
        <Presentation/>

    </div>
  )
}

export default QuiSommes