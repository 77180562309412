import React from 'react'
//import Qui from '../img/quisom1.jpg'
import Structure from '../img/structure.jpg'
import Indus from '../img/indus.jpg'
//import Industrie from '../img/industrie.jpg'
import Btp from '../img/btp.jpg'
import Energies from '../img/energies1.jpg'
import Energie from '../img/energie.jpg'
import Pdg from '../img/pdg.jpg'
import Pdgadj from '../img/pdgadj.jpg'
import Btpres from '../img/btprespo.jpg'
import Indusrespo from '../img/industRespo.jpg'
import Energierespo from '../img/energieRespo.jpg'
import Coordo from '../img/coordo.jpg'
import {motion} from 'framer-motion'



function Presentation() {
  return (
    <div className='grid md:grid-cols-2 sm:grid-cols-2 gap-8 mt-2 p-3 bg-white py-4'>
        

    
        <motion.div 
        initial={{ x:-200,  opacity:0, }}
        transition={{
        duration:1.2,
        }}
        whileInView={{opacity:1, x:0}}
        viewport={{once:true}}
         id='right' 
         className='p-4'>
        
           
        <div className='mt-4 flex flex-col'>

            
                <div className='flex w-full items-center justify-center'><p className='flex items-center text-center bg-orange-500 p-4 rounded-lg text-white font-extrabold'> <b>Notre Organisation</b></p></div>
                <div className='flex flex-col'>
                    <div className='grid sm:grid-cols-2 gap-8 items-center  justify-between p-4'>
                       <div className='flex items-center space-x-6'> 
                            <img src={Pdg} alt="MOHAMED CISSÉ Directeur Général Ps-Group" className='w-[100px] h-[139px] drop-shadow-lg rounded-lg'/>
                            <div className='flex flex-col items-center space-y-2'>
                                <p className='font-extrabold text-xs'><b>MOHAMED CISSÉ</b></p>
                            <div className='bg-green-200 text-xs font-bold px-4 py-1 rounded-lg drop-shadow-md w-[100px] flex flex-col items-center justify-center'>
                            <p><b>Directeur</b></p>
                            <p><b>Général</b></p>
                            </div>
                            </div>
                        </div>
                        <div className='flex items-center space-x-6'> 
                        <div className='flex flex-col items-center space-y-2'>
                                <p className='font-extrabold text-xs'><b>CHEICKNA TANDIA</b></p>
                                <div className='bg-green-200 text-xs font-bold px-4 py-1 rounded-lg drop-shadow-md w-[100px] flex flex-col items-center justify-center'>
                                <p><b>Directeur</b></p>
                                <p><b>Général</b></p>
                                <p className=''><b>Adjoint</b></p>
                                </div>
                            </div>
                            <img src={Pdgadj} alt="CHEICKNA TANDIA Directeur Général Adjoint de PS-Group" className='w-[100px] h-[139px] drop-shadow-lg rounded-lg '/>
                            
                        </div>
                        
                    </div>

                    <div className='flex p-4 items-center space-x-6 justify-center '>
                    <img src={Btpres} alt="BRUNO DEL PIERO Responsable BTP de Ps-Group" className='w-[100px] h-[139px] drop-shadow-lg rounded-lg'/>
                    <div className='flex flex-col items-center space-y-2'>
                                <p className='font-extrabold text-xs'><b>BRUNO DEL PIERO</b></p>
                            <div className='bg-green-200 text-xs font-bold px-4 py-1 rounded-lg drop-shadow-md w-[100px] flex flex-col items-center justify-center'>
                                <p><b>Responsable</b></p> 
                                <p><b>BTP</b></p>
                            </div>
                    </div>
                    </div>
                    
                    <div className='flex p-4 items-center space-x-6 justify-center '>
                    <img src={Indusrespo} alt="PIERRE GODAR Responsable Industrie de Ps-Group" className='w-[100px] h-[139px] drop-shadow-lg rounded-lg'/>
                    <div className='flex flex-col items-center space-y-2'>
                                <p className='font-extrabold text-xs'><b>PIERRE GODAR</b></p>
                            <div className='bg-green-200 text-xs font-bold px-4 py-1 rounded-lg drop-shadow-md w-[100px] flex flex-col items-center justify-center'>
                                <p><b>Responsable</b></p> 
                                <p><b>Industrie</b></p>
                            </div>
                    </div>
                    </div>

                    <div className='flex p-4 items-center space-x-6 justify-center '>
                    <img src={Energierespo} alt="KADIJA SIMBORO Responsable Energie Ps-Group" className='w-[100px] h-[139px] drop-shadow-lg rounded-lg'/>
                    <div className='flex flex-col items-center space-y-2'>
                                <p className='font-extrabold text-xs'><b>KADIJA SIMBORO</b></p>
                            <div className='bg-green-200 text-xs font-bold px-4 py-1 rounded-lg drop-shadow-md w-[100px] flex flex-col items-center justify-center'>
                                <p><b>Responsable</b></p> 
                                <p><b>Energie</b></p>
                            </div>
                    </div>
                    </div>


                    <div className='flex p-4 items-center space-x-6 justify-center '>
                    <img src={Coordo} alt="BERNARD N’GUESSAN Responsable Coordination de Ps-Group" className='w-[100px] h-[139px] drop-shadow-lg rounded-lg'/>
                    <div className='flex flex-col items-center space-y-2'>
                                <p className='font-extrabold text-xs'><b>BERNARD N’GUESSAN</b></p>
                            <div className='bg-green-200 text-xs font-bold px-4 py-1 rounded-lg drop-shadow-md w-[100px] flex flex-col items-center justify-center'>
                                <p><b>Responsable</b></p> 
                                <p><b>Coordination</b></p>
                            </div>
                    </div>
                    </div>

                </div>
            </div>










           
        </motion.div>


        <motion.div 
        initial={{ x:200,  opacity:0, }}
        transition={{
        duration:1.2,
        }}
        whileInView={{opacity:1, x:0}}
        viewport={{once:true}}
        className='p-4'>
            
            <p className='text-justify'>
                En prenant acte de la réalité du réchauffement climatique dans la décennie 90, et de la responsabilité  humaine dans ces dérèglements, les dirigeants politiques ont initié  une politique de lutte contre le réchauffement de la planète. Le protocole de Kyoto est l'exemple le plus actuel d'une stratégie de réduction des gaz à effet de serre. <br /> 
                Mais, celui-ci venant à expiration en 2012, l'enjeu est désormais de renouveler une politique mondiale de lutte contre le changement climatique dans un contexte de montée des périls environnementaux, c’est dans cette optique que les promoteurs <b>(MM. Mohamed CISSÉ  et Cheickna TANDIA ) </b> et leurs partenaires ont décidé  de créer cette plateforme qui aura certes des objectifs économiques mais aussi d’encourager et de sensibiliser à la protection et de la préservation de l’environnement. <br /> 
            </p>
        </motion.div>


        
        <motion.div 
        initial={{ x:-200,  opacity:0, }}
        transition={{
        duration:1.2,
        }}
        whileInView={{opacity:1, x:0}}
        viewport={{once:true}}
        id='left' className='p-4'>
        <p className='font-extrabold text-xl mb-2'>Que faisons-nous ?</p>
            <p className='text-justify'>
                
                <b>PLANETE SOLUTION GROUP </b> envisage d’offrir une large gamme de concassés de granites sûrs, des infrastructures intelligentes qui amélioreront la vie des êtres vivants et qui permettront la réduction de la consommation en énergies en fournissant de l’énergie verte et d’autres types d’énergies non polluante. <br /> 
                Nos principales activités sont l’industrie extractive (exploitation industrielle de granite concassé) et le BTP (la construction de bâtiments et les travaux publics). <br /> 

            </p>
        </motion.div>
        <motion.div 
        initial={{ x:200,  opacity:0, }}
        transition={{
        duration:1.2,
        }}
        whileInView={{opacity:1, x:0}}
        viewport={{once:true}}
        className='p-4'>
        
        
            <img src={Structure} alt="" className='drop-shadow-md'/>
        </motion.div>


        <div id='right' className='p-4'>
        <p className='sm:hidden font-extrabold text-xl mb-2'>Industrie Extrative</p>
            <img src={Indus} alt="" className='drop-shadow-md'/>
        </div>
        <div className='p-4'>
            <p className='hidden sm:flex font-bold text-xl mb-2'>INDUSTRIE EXTRATIVE</p>
            <p className='text-justify'>
            Consiste en l’exploitation industrielle de granite concassé dans une carrière d’où sont extraites une large gamme de concassés de granite à partir d’un gisement de roche. Ces concassés de granite s’utilisent ensuite dans les chantiers pour la construction des bâtiments, des routes, des ponts et autres infrastructures. <br />
            PLANETE SOLUTION GROUP  utilise dans sa carrière un procédé de production respectueux de l’environnement. 
  
            </p>
            
        </div>


        <div id='left' className='p-4'>
        <p className='font-extrabold text-xl mb-2'>BTP</p>
            <p className='text-justify'>
                
                <b>PLANETE SOLUTION GROUP </b> envisage d’offrir une large gamme de concassés de granites, d'infrastructures intelligentes qui amélioreront le cadre de vie et qui permettront la réduction de la consommation en énergies en fournissant de l’énergie verte et d’autres types d’énergies non polluante. <br /> 
                Nos principales activités sont l’industrie extractive (exploitation industrielle de granite concassé) et le BTP (la construction de bâtiments et les travaux publics). <br /> 

            </p>
            
        </div>
        <div className='p-4'>
        
        
            <img src={Btp} alt="" className='drop-shadow-md'/>
        </div>



        <div id='right' className='p-4'>
        <p className='sm:hidden font-extrabold text-xl mb-2'>Industrie Extrative</p>
            <img src={Energies} alt="" className='drop-shadow-md'/>
        </div>
        <div className='p-4'>
            <p className='hidden sm:flex font-bold text-xl mb-2 uppercase'>Energies Vertes</p>
            <p className='text-justify'>
            PLANETE SOLUTION GROUP propose des solutions innovantes dans les énergies vertes avec les nouvelles sources d’énergies telles que le solaire et l’éolienne. <br />
PLANETE SOLUTION GROUP offre également des produits adaptés pour les besoins en électricité et en eau pour les populations encore isolées.
 
  
            </p>
            <div className='flex justify-between mt-4'><p></p><img src={Energie} className="w-[250px] drop-shadow-md" alt=""  /></div>
        </div>


    </div>
  )
}

export default Presentation