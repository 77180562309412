import React from 'react'
import Dispo from './Dispo'
import {Granites} from '../data/granitesData'
import { Link } from 'react-router-dom'


function Disponible() {
  return (
    <div className='relative flex flex-col mt-6 appBackground items-center'>
       <div className='absolute w-full h-full bg-black/10 ' />
        <div className='z-[2] flex  items-center justify-center text-2xl sm:text-4xl text-center text-white uppercase gap-x-3 my-6 font-bold'>
        <p className= 'flex flex-col bg-slate-500/70 items-center justify-center self-center w-full shadow-lg p-5 rounded-xl'>Actuellement disponible chez nous</p> 
        </div>
        <div  className=" grid md:grid-cols-2 lg:grid-cols-3 gap-6 z-[4]">
          
           {Granites && Granites.map((data)=>(
            <div
            key={data.id}
            className=""
            >
              <Dispo 
              imgSrc={data.imgSrc}
              nom={data.nom}
              qualite={data.qualite}
              />

            </div>
           ))}
         
        </div>

        <div className='z-[2]'>
        <button className='rounded-lg bg-slate-500 px-5 py-2 text-xl text-white hover:bg-slate-400 my-6 cursor-pointer font-bold '>

        <Link to='/produits'>
          voir plus de Produits
        </Link>
        </button>
        </div>
    </div>
  )
}

export default Disponible