import React from 'react'
import video from '../img/video3.mp4'
//import { useTypewriter, Cursor } from 'react-simple-typewriter'

function Hero() {

  
  return (
    <div className='w-full mx-auto top-0'>
      {/*<div className="overlay"></div>*/}
      <div className="content  ">
      <h1 className=' absolute text-orange-600  text-2xl sm:text-5xl top-[20%] left-[5px]  sm:left-[10px] z-[4] font-bold  tracking-[10px] '>PLANETE SOLUTION GROUP</h1>
          <h1 className=' absolute text-white  text-xl sm:text-5xl top-[35%]  z-[4] font-bold left-[5px] sm:left-[10px] md:flex-nowrap '>Exploitation de substances de carrière industrielle</h1>
          <p className=' absolute text-white  text-lg sm:text-2xl top-[45%]  z-[4]  left-[5px]  sm:left-[10px] '>Bientôt une capacité de production de 300 à 500 tonnes/heure</p>
          <h1 className=' absolute text-orange-600 text-xl sm:text-5xl top-[55%]  z-[4] font-bold left-[5px]  sm:left-[10px] '>Le bonheur d'une planète saine</h1>
      </div>
      <div className='absolute w-[1440px] h-[450px] bg-black/40 z-[2]' />
      <video 
        src={video} 
        autoPlay loop muted 
        className='w-[1440px] h-[450px] object-cover'
      />

    
    </div>
  )
}

export default Hero