import React from 'react'
import Top from './Top'
import logo3 from '../img/logo3.png'
import Dv from '../img/dv.jpg'
import { motion } from 'framer-motion'
import { useStateValue } from '../StateProvider'
import { useNavigate } from 'react-router-dom'


function Commande() {

  const [{basket}, dispatch] = useStateValue();
  const navigate = useNavigate();

  const Order =()=>{

    dispatch({
      type: "EMPTY_BASKET",
    });

    alert(
      "Notre équipe prendra contacte avec vous d'ici peu"
    );
    navigate('/')
  }

 
  


  return (
    <div>
        <Top  image={Dv}/>

        <motion.div
    initial={{y:-100, opacity:0, }}
    transition={{duration:1.2}}
    whileInView={{opacity:1, y:0}}
    viewport={{once: true}}

    className='grid md:grid-cols-2 gap-4'
    >

       <div><div className='tracking-[10px] text-center font-extrabold  text-xl  sm:mt-10 text-black px-40 mx-auto'> 
          Merci de nous envoyer votre commande
        </div>

        <div className='flex-1 items-center'>
            <form className=''>

              <div className='flex space-x-2'>
                <p className='flex-1'>
                  <input 
                  type="text" 
                  placeholder="Votre nom et prenoms"
                  className="w-full m-2  border-b-slate-800 border-2 no-select p-2 outline-none rounded-md"
                  />
                </p>

                <p className='flex-1'>
                  <input 
                  type="text" 
                  placeholder="nom de votre structure"
                  className="w-full m-2  border-b-slate-800 border-2 no-select p-2 outline-none rounded-md"
                  />
                </p>

              </div>

              <div className='flex space-x-2'>
                <p className='flex-1'>
                  <input 
                  type="email" 
                  placeholder="Votre email"
                  className="w-full m-2  border-b-slate-800 border-2 no-select p-2 outline-none rounded-md"
                  />
                </p>

                <p className='flex-1'>
                  <input 
                  type="text" 
                  placeholder="Votre Numéro cell.."
                  className="w-full m-2  border-b-slate-800 border-2 no-select p-2 outline-none rounded-md"
                  />
                </p>
                
              </div>
              <div className=' ml-2 p-4 bg-slate-200 rounded-md drop-shadow-md border-2 border-blue-400'>
                <p>Nous souhaiterons avoir les articles suivants:</p>
                {basket && basket.map((item)=>(
                    <div
                      key={item.id}
                      className="mx-4"
                    >
                        <ul className='flex gap-x-2 text-sm font-bold'>
                        <p>-</p><li>{item.nom}</li>
                          <li>{item.qualite}</li>
                        </ul>
                    </div>
                ))}
 

              </div>
              <button className="w-full m-2 p-2 rounded-md bg-cyan-500 hover:bg-cyan-600 text-2xl text-white"  onClick={Order}>
                Valider ma Commande
              </button>
            </form>

          </div></div>
          <div className='flex-1 justify-center items-center w-full '>
               
               <img src={logo3} alt="Logo de PS-Group" className='w-[300px] mx-auto' />
               <p className='text-center text-xl font-bold'>Merci de nous faire confiance</p>
               
           </div>


    </motion.div>


    </div>
  )
}

export default Commande